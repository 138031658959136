<template>
  <div class="container mt-2 p-0t">
    <b-row class="d-flex">
      <b-col md="6" class="mb-3 align-items-stretch">
        <b-card class="text-center same-height">
          <feather-icon icon="FilmIcon" size="100" class="primary-color mb-2" />
          <p class="mb-0 line-height">
            {{
              $t(
                "Pages.Channel.Title.get_full_report_vid"
              )
            }}
          </p>
          <b-button
            variant="primary"
            class="w-fit mt-2"
            @click="updateVideosList"
            >{{ $t("Pages.Channel.Button.get_vid_report") }}</b-button
          >
        </b-card>
      </b-col>
      <b-col md="6" class="mb-3 align-items-stretch">
        <b-card class="text-center same-height">
          <feather-icon
            icon="MessageSquareIcon"
            size="100"
            class="primary-color mb-2"
          />
          <p class="mb-0 line-height">
            {{
              $t(
                "Pages.Channel.Title.get_full_report_comments"
              )
            }}
          </p>
          <b-button
            variant="primary"
            class="w-fit mt-2"
            @click="updateCommentsList"
            >{{ $t("Pages.Channel.Button.get_comments_report") }}</b-button
          >
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <div class="col-5 mt-5" id="container">
        <b-alert class="p-1" variant="warning" v-if="this.showAlert" show>{{
          $t("Base.Alert.please_wait")
        }}</b-alert>
        <b-alert
          class="p-1"
          variant="success"
          v-if="this.showEmailAlert"
          @dismissed="hideAlerts"
          dismissible
          show
          >{{
            $t("Base.Alert.excel_email_soon")
          }}</b-alert
        >
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BAlert,
  BBadge,
  BButton,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BInputGroup,
  BInputGroupAppend,
  BCardHeader,
  BCardBody,
  BOverlay,
  BPaginationNav,
  BFormInput,
  BModal,
  VBModal,
  BFormTextarea,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import MyDate from "@core/components/date/MyDate.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BImg,
    BLink,
    BButton,
    BAlert,
    BBadge,
    BTooltip,
    BCardHeader,
    vSelect,
    BOverlay,
    BPaginationNav,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BModal,
    BFormTextarea,
  },
  data() {
    return {
      type: "",
      showAlert: false,
      showEmailAlert: false,
    };
  },

  methods: {
    updateVideosList() {
      this.type = "video";
      this.showAlert = true;
      this.showEmailAlert = false;
      this.$http
        .get(
          `/api/channel/studio/videos/update-list?channel_id=${this.$route.params.channel_id}`
        )
        .then((data) => {
          this.showAlert = false;
          this.showEmailAlert = true;
          this.createExcel();
        });
    },
    updateCommentsList() {
      this.type = "comment";
      this.showAlert = true;
      this.showEmailAlert = false;
      this.$http
        .get(
          `/api/channel/studio/${this.$route.params.channel_id}/comments/update-list`
        )
        .then((data) => {
          this.showAlert = false;
          this.showEmailAlert = true;
          this.createExcel();
        });
    },
    createExcel() {
      this.$http
        .get(
          `/api/channel/studio/${this.$route.params.channel_id}/${this.type}/backup`
        )
        .then((data) => {});
    },
    hideAlerts() {
      this.showAlert = false;
      this.showEmailAlert = false;
    },
  },
};
</script>

<style scoped>
#container {
  padding: 0px;
}
</style>

<style scoped>
.h-100 {
  height: 100% !important;
}

.primary-color {
  color: var(--primary);
}

.same-height .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.same-height {
  height: 100%;
}
.line-height {
  line-height: normal;
}
</style>
